import React from 'react'
import './About.css'
import profile_img from '../../Assets/about_profile.svg'

const About = () => {
  return (
    <div id = 'about' className='about'>
        <div className="about-title">
            <h1>About me</h1>
        </div>
        <div className="about-sections">
            <div className="about-left">
                <img src={profile_img} alt="" />
            </div>
            <div className="about-right">
                <div className="about-para">
                    <p>I'm a frontend developer with a passion for creating beautiful and functional user interfaces, with an interest in ML and linguistics. I have experience developing web applications using React and Next.js, and always curious to expand my skillset.</p>
                    <p>In my free time I love to cook with my family and play board games.</p>
                </div>
                <div className="about-skills">
                    <div className="about-skill"><p>HTML & CSS</p><hr style={{width:"50%"}} /></div>
                    <div className="about-skill"><p>React JS</p><hr style={{width:"70%"}} /></div>
                    <div className="about-skill"><p>JavaScript</p><hr style={{width:"60%"}} /></div>
                    <div className="about-skill"><p>Next JS</p><hr style={{width:"50%"}} /></div>
                </div>
            </div>
        </div>
        <div className="about-achievements">
            <div className="about-achievement">
                <h1>3</h1>
                <p1>PROJECTS FINISHED</p1>
            </div>
            <hr />
            <div className="about-achievement">
                <h1>2+</h1>
                <p1>YEARS OF JAVA AND JAVASCRIPT EXPERIENCE</p1>
            </div>
            <hr />
            <div className="about-achievement">
                <h1>4+</h1>
                <p1>SATISFIED CLIENTS</p1>
            </div>
        </div>
    </div>
  )
}

export default About