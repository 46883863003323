import React from 'react'
import './MyWork.css'
import mywork_data from '../../Assets/mywork_data.js'
import arrow_icon from '../../Assets/arrow_icon.svg'

const MyWork = () => {
  return (
    <div id = 'portfolio' className='mywork'>
      <div className="mywork-title">
        <h1>My Latest Work</h1>
        
      </div>
      <div className="mywork-container">
        {mywork_data.map((work,index)=>{
          return <img key={index} src={work.w_img} alt="" />
        })}
      </div>
      <div className="mywork-showmore">
        <p>Show More</p>
        <img src={arrow_icon} alt="" />
      </div>
    </div>
  )
}

export default MyWork