import React from 'react'
import './Resume.css'
import theme_pattern from '../../Assets/theme_pattern.svg'
import mail_icon from '../../Assets/mail_icon.svg'
import location_icon from '../../Assets/location_icon.svg'
import call from '../../Assets/call_icon.svg'
import { ToastContainer, toast } from 'react-toastify';
import { Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", "52e67f1f-90e6-4efc-97f3-addd136fe2f5");
    
        try{
          const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });
    
        const data = await response.json();

    
        if (data.success) {
          toast.success("Form Submitted Successfully", {
            hideProgressBar: true,
            autoClose: 1500
          });
          event.target.reset();
        } else {
          toast.error(data.message);
        }
    } catch (error) {
        toast.error("An error occurred. Please try again.");
    }  
};


  return (
    <div id = 'contact' className='contact'>
        <div className="contact-title">
            <h1>Get in touch</h1>
            <img src={theme_pattern} alt="" />
        </div>
        <div className="contact-section">
            <div className="contact-left">
                <h1>Let's talk</h1>
                <p>Ready to take on new projects, please contact me with any inquiries/requests</p>
                <div className="contact-details">
                    <div className="contact-detail">
                        <img src={mail_icon} alt="" />  <p>rs8017@princeton.edu</p>
                    </div>
                    <div className="contact-detail">
                        <img src={call} alt="" />  <p>224-540-3359</p>
                    </div>
                    <div className="contact-detail">
                        <img src={location_icon} alt="" />  <p>IL, United States</p>
                    </div>
                </div>
            </div>
            <form onSubmit={onSubmit} className="contact-right">
                <label htmlFor="">Your Name</label>
                <input type="text" placeholder='Enter your name' name='name' />
                <label htmlFor="">Your Email</label>
                <input type="email" placeholder='Enter your email' name='email' />
                <label htmlFor="">Write your message here</label> 
                <textarea name="message" rows="8" placeholder='Enter your message'></textarea>
                <button type='submit' className='contact-button'>Submit</button>
            </form>
        </div>
        <ToastContainer position= "top-center" autoClose={2500} hideProgressBar={false} transition={Slide}/>
    </div>
    
  )
}

export default Contact