import React from 'react'
import './Header.css'
import profile_img from '../../Assets/profile_img.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Header = () => {
  return (
    <div id = 'home'className='header'>
        <img src={profile_img} alt="" />
        <h1><span>Hi, I'm Ranveer Singh,</span> a freshman studying computer science at Princeton University.</h1>
        <p></p>
        <div className="header-action">
            <div className="header-connect"><AnchorLink className='anchor-link' offset={50} href='#contact'>Connect with me</AnchorLink></div>
            <div className="header-resume">
              <a
                className= 'anchor-link'
                href="https://drive.google.com/file/d/1CA8yOTBFhgo6X9iWHVhD86SjqEc5bzRH/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                My resume
              </a>  
            </div>
        </div>
    </div>
  )
}

export default Header